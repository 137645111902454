@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi+Fun:wght@400..700&family=Tilt+Neon&display=swap');

* {
  margin: 0;
  font-family: "Reem Kufi Fun";
  color: #333;
}



body {
  /* background-image: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.75)); */
  /* background-color: #333; */
  background-color: rgba(84, 84, 86, 1);

}

.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}

