.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
  color: #f2f2f2;
  background-color: rgba(0,0,0,0.25);
}

.navbar h1 {
  color: #FFFFFF
}

.navbar .pages {
  margin-left: auto;
}


.navbar a{
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
  color: #FFFFFF
}

.navbar a:hover {
  color: #f1356d
}
