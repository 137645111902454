.ttrplayers {
  padding: 20px 20px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 225px);
  background-color: rgba(0,0,0,0);
  grid-gap: 10px;
  }
  
  /* .ttrplayer {
  
    box-sizing: border-box;
  } */
  
  .ttrplayer .counters {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px ;
    padding-bottom: 5px;
    
  }

  .ttrplayer .ttrroutes{

    padding-left: 10px;
    padding-right: 11px;
    padding-bottom: 5px;
  }
  
  .ttrplayer .scores{
    background-color: rgba(26,26,26,.5);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 50px;
    padding-left: 10px;
  }
  
  .ttrplayer .scores .breakdown span{
    font-size: .65rem;
    padding: 2.5px;
    color:#f2f2f2
  }
  
  .ttrplayer .scores .totalScore{
    display: inline-grid;
    grid-template-columns: repeat(2, 100px);
    
  }
  
  .ttrplayer .scores .totalScore textarea{
    justify-content: left;
    background-color: rgba(0,0,0,0);
    max-width: 120px;
    color:#f2f2f2;
    height: 20px;
    resize: none;
    border: none;
  }
  .ttrplayer .scores .totalScore textarea:focus{
    outline: none;
  }
  .ttrplayer .scores .totalScore p{
    display: flex;
    justify-content: left;
    /* color: #f2f2f2; */
    color:yellow;
    width: 120px;
  }
  
  .ttrplayer:hover {
    box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
  }
  
  /* .building button {
    width: 25px;
    background-color: #f2f2f2;
    border-color: #f2f2f2;
  } */
  
  .track{
    display: inline-grid;
    grid-template-columns: repeat(2, 120px);
  }
  
  .track .buttons{
    max-width: 50px;
  }
  .track button {
    background-color: rgba(128, 128, 128, .45);
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
    font-size: 14px;
    line-height: 20px;
    padding: 2px 1px;
    position:relative;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    width: 25px;
  }
  
  .track button:hover {
    background-color: rgba(128, 128, 128, .65);
  }
  
  .resetButton:hover {
    background-color: red;
    color: white;
  }